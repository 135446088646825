import JsBarcode from 'jsbarcode';
import QRious from 'qrious';

/*
 * Takes an abbreviation for a country and returns the long form.
 * If no conversion is present, an empty value is returned.
 */
export const countryAbbreviationToFullName = country => {
  if (country === 'TW') {
    return 'Taiwan';
  } else if (country === 'CH') {
    return 'China';
  } else if (country === 'US') {
    return 'USA';
  }

  return;
};

/*
 * Process a country of origin to display to a customer.
 * If just a known country abbreviation is passed, it converts to a "Made in CountryName" form
 * If a different value is passed, it converts all "|" to a line break.
 */
export const countryOfOriginDisplayText = coo => {
  // Get the unabbreviated form of the country if applicable.
  const country = countryAbbreviationToFullName(coo);

  // If the value passed was just a country, return that.
  if (country) {
    return `Made in ${country}`;
  }

  // Else insert line breaks.
  return coo.replace('|', '\n');
};

/**
 * Scales a font size down until it fits in the allowed maximum width
 * @param text
 * @param maxWidth
 * @param font
 * @param startingFontSize
 * @returns {*}
 */
export const scaleFontSizeToFit = (text, maxWidth, font, startingFontSize) => {
  let fontSize = startingFontSize;

  while (font.widthOfTextAtSize(text, fontSize) > maxWidth) {
    fontSize -= 0.5;

    if (fontSize <= 3) {
      return fontSize;
    }
  }

  return fontSize;
};

/**
 * Take in text and remove invalid characters that will throw errors.
 * @param text
 * @returns string
 */
const processText = text => {
  return text.replace(/\n/g, '');
};

/**
 * Create line breaks or cut text off when text is longer than the maximum width and line count.
 * @param originalItemDescription
 * @param maxWidth
 * @param numberLines
 * @param font
 * @param fontSize
 * @returns {*}
 */
export const generateLineBreakText = (
  originalItemDescription,
  maxWidth,
  numberLines,
  font,
  fontSize
) => {
  // Determine the offset to center the text.
  let itemDescription = processText(originalItemDescription || '');

  let itemDescriptionLengthSize = font.widthOfTextAtSize(
    itemDescription,
    fontSize
  );

  // Adjust the max width down by the font size slightly since the built-in line break features weighs "\n"
  // line break characters as longer then the " " character.
  const adjustedMaxWidth = maxWidth - fontSize;

  // Remove any text that will not fit.
  while (itemDescriptionLengthSize > adjustedMaxWidth * numberLines) {
    itemDescription = itemDescription.substring(0, itemDescription.length - 1);
    itemDescriptionLengthSize = font.widthOfTextAtSize(
      itemDescription,
      fontSize
    );
  }

  // If the text already fits on one line, return early.
  if (itemDescriptionLengthSize <= adjustedMaxWidth) {
    return itemDescription;
  }

  const spaceIndexes = [];
  for (let i = 0; i < itemDescription.length; i++) {
    if (itemDescription[i] === ' ') {
      spaceIndexes.push(i);
    }
  }
  // We also add a check at the end of the string to ensure the last word wraps if necessary.
  spaceIndexes.push(itemDescription.length);

  if (spaceIndexes.length === 0) {
    return itemDescription;
  }

  const indexOfLineBreak = [];

  // Loop through and insert line breaks in whitespace right before the text reaches the
  // maximum width of the line
  let startIndex = 0;

  for (let i = 1; i < spaceIndexes.length; i++) {
    const endIndex = spaceIndexes[i];
    const widthOfSubstring = font.widthOfTextAtSize(
      itemDescription.substring(startIndex, endIndex),
      fontSize
    );

    if (widthOfSubstring > adjustedMaxWidth) {
      // Use the last whitespace index that fits within the maximum width.
      const latestWhitespaceIndex = spaceIndexes[i - 1];
      indexOfLineBreak.push(latestWhitespaceIndex);
      startIndex = latestWhitespaceIndex + 1;
    }
  }

  for (let i = 0; i < indexOfLineBreak.length; i++) {
    // If the line break is beyond the number of line breaks to display, the text is cut off.
    if (i + 1 === numberLines) {
      itemDescription = itemDescription.slice(0, indexOfLineBreak[i]);
      return itemDescription;
    }

    itemDescription = `${itemDescription.slice(0, indexOfLineBreak[i])}\n${itemDescription.slice(indexOfLineBreak[i] + 1)}`;
  }

  return itemDescription;
};

/**
 *
 * @param number
 * @param minNumDecimalPlaces
 * @param maxNumDecimalPlaces
 * @returns {string}
 */
export const prettyNumber = (
  number,
  minNumDecimalPlaces = 2,
  maxNumDecimalPlaces = 10
) => {
  if (!number) {
    return '';
  }

  return parseFloat(number).toLocaleString('en-US', {
    minimumFractionDigits: minNumDecimalPlaces,
    maximumFractionDigits: maxNumDecimalPlaces,
  });
};

/*
 * GitHub: https://github.com/lindell/JsBarcode
 */
export const createBarcode = (format, barcode, barcodeHeight) => {
  const test = document.createElement('canvas');

  // ITF14 must always have 14 digits - ex: 12345678901231

  JsBarcode(test, barcode, {
    format,
    width: 2,
    height: barcodeHeight,
    // So that text does not display below the barcode
    fontSize: 0,
    textMargin: 0,
    // Negative margins remove the margin altogether.
    marginTop: -0.001,
    marginBottom: -0.001,
    marginLeft: 1,
    marginRight: 1,
  });

  return test.toDataURL();
};

/*
 * https://github.com/neocotic/qrious
 */
export const createQRCode = (barcode, barcodeHeight) => {
  const qr = new QRious();
  qr.set({
    level: 'M',
    padding: null,
    size: barcodeHeight,
    value: barcode,
  });

  return qr.toDataURL();
};
