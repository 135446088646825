const images = {};

/**
 * Load an image from a URL and cache it.
 * @param {string} url
 * @returns {Promise<ArrayBuffer>}
 */
export const loadImage = async url => {
  if (images[url]) {
    return images[url];
  }

  const image = await fetch(url).then(res => res.arrayBuffer());
  images[url] = image;
  return image;
};
