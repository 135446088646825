import {useEffect, useState} from 'react';
const Select = props => {
  const disabled = props.disabled || false;
  const [defaultValue, setDefaultValue] = useState(
    props.defaultValue || undefined
  );

  useEffect(() => {
    setDefaultValue(props.defaultValue || undefined);
  }, [props.defaultValue]);

  const optionsList = [];
  for (const option of props.options) {
    optionsList.push(
      <option key={option.key} value={option.value}>
        {option.label}
      </option>
    );
  }

  const onChange = event => {
    setDefaultValue(event.target.value);
    props.handleChange(event.target.value);
  };

  return (
    <div className={'select-container'}>
      <select disabled={disabled} onChange={onChange} value={defaultValue}>
        {optionsList}
      </select>
    </div>
  );
};

export {Select};
