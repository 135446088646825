import ConfirmLinesDropdown from './ConfirmDropdown.js';

/**
 * Generates the options for the suboptions that appear on tables
 * @param props
 * @param {Object} props.lines,
 * @param {function} props.setLines,
 * @param {Object} props.header,
 * @param {function} props.setHeader,
 * @param {array} props.suboptions,
 * @param {string} props.suboptions.key,
 * @returns {*[]}
 * @constructor
 */
const TableSuboptions = props => {
  const {lines, setLines, header, setHeader, suboptions = []} = props;

  const suboptionsContent = [];

  for (const suboption of suboptions) {
    // create a unique key for each cell
    const key = suboption.options.key;

    if (suboption.type === 'confirmdropdown') {
      suboptionsContent.push(
        <ConfirmLinesDropdown
          lines={lines}
          setLines={setLines}
          header={header}
          setHeader={setHeader}
          orderNumber={suboption.options.orderNumber}
          key={key}
        />
      );
    }
  }
  return suboptionsContent;
};

export default TableSuboptions;
