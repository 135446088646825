import clsx from 'clsx';

/**
 * Create Badges
 * @param {Object} props
 * @param {Array} [props.badges] - An array of objects describing badges
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const Badge = props => {
  const {badges = [], className} = props;

  const badgeChildren = [];

  badges.forEach(badge => {
    badgeChildren.push(
      <span
        key={badge.value}
        className={clsx(
          `text-xs text-white rounded-[12px] py-1 px-2.5`,
          // We add top margin to every badge so that they stay spaced apart.
          badges.length >= 1 && 'mt-[4px]',
          badge.color
        )}
      >
        {badge.value.toUpperCase()}
      </span>
    );
  });

  return <div className={clsx('inline-grid', className)}>{badgeChildren}</div>;
};

export default Badge;
