import {useState, useMemo} from 'react';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import Section from '../Section.js';
import {Checkbox} from '../Checkbox.js';
import {InputWithShiftingLabel} from '../TextInput.js';
import Line from './line/Line.js';
import LineHeader from './line/LineHeader.js';
import TableSuboptions from './suboptions/TableSuboptions.js';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Table = props => {
  /*
   * Functions
   */

  const toggleCheckboxes = (checked, num) => {
    let temp = [];
    // In order to trigger a re-render we have to create
    // a new array rather then modifying the existing one
    temp = temp.concat(checkboxFilters);
    temp[num].value = checked;
    setCheckboxFilters(temp);
  };

  /*
   * Setup
   */
  let pageCount = Infinity;

  const {
    data,
    setData,
    header,
    setHeader,
    hasDataLookupFinished,
    searchFilters,
    pageSize,
    showPagination = true,
    title,
    suboptions,
    className,
  } = props;

  const [openPage, setOpenPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortCriteria] = useState(props.sortCriteria);
  const [checkboxFilters, setCheckboxFilters] = useState(props.checkboxFilters);

  const sortedData = [];

  /**
   * Update a single cell column
   * @param newValue
   * @param {string} cellKey
   * @param {string} objKey
   */
  const updateSingleCell = (newValue, cellKey, objKey) => {
    const temporaryData = {...data};
    temporaryData[objKey][cellKey] = newValue;
    setData(temporaryData);
  };

  /**
   * Update every value in a column
   * @param newValue
   * @param {string} key
   */
  const updateFullColumn = (newValue, key) => {
    const temporaryData = {...data};
    sortedData.forEach(line => {
      if (!line.isVisible) {
        return;
      }

      temporaryData[line.key][key] = newValue;
    });
    setData(temporaryData);
  };

  // Remove cells
  const lineFormat = useMemo(() => {
    return props.lineFormat.filter(column => {
      // Always show the column if hideWhenEmpty is false or undefined
      if (!column.hideWhenEmpty) {
        return true;
      }

      // Otherwise, loop through all data columns to see
      // if the value is ever present.
      for (const [key] of Object.entries(data)) {
        // As soon as one cell is defined we need to keep the entire column
        if (
          data[key][column.key] !== undefined &&
          data[key][column.key] !== null
        ) {
          return true;
        }
      }
      // There is no need to display this cell so it is removed
      // from the required lineFormat
      return false;
    });
  }, [props.lineFormat, data]);
  let pagination = '';

  const goToPage = pageNum => {
    if (pageNum >= 0 && pageNum < pageCount) {
      setOpenPage(pageNum);
    }
  };

  /*
    This sort function loops through the sort criteria.
    If sort result is 0, it does not return a value and
    instead loops to the next criteria in the sort criteria array.
   */
  const sortFunction = (po1, po2) => {
    if (sortCriteria) {
      for (let i = 0; i < sortCriteria.length; i++) {
        let result = 0;
        // Get the data key to sort on
        const key = sortCriteria[i].key;
        // Sort differently based on the data type
        switch (sortCriteria[i].type) {
          case 'date':
            // If either PO's date isn't a valid date, move it to the end.
            {
              let date1, date2;
              try {
                if (!po1[key]) {
                  throw '';
                }
                date1 = new Date(po1[key]);
              } catch (e) {
                /* A missing or invalid date gets us here */
                // console.log(e);
              }

              try {
                if (!po2[key]) {
                  throw '';
                }
                date2 = new Date(po2[key]);
              } catch (e) {
                /* A missing or invalid date gets us here */
                // console.log(e);
              }

              // If the POs don't have dates, move them to the end
              if (!date1 && !date2) {
                result = 0;
              } else if (!date1) {
                // Move PO1 towards the end
                result = 1;
              } else if (!date2) {
                // Move PO2 towards the end
                result = -1;
              } else {
                // Move based on compared value
                result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
              }
            }
            break;
          case 'string':
            result =
              po1[key].toLowerCase() < po2[key].toLowerCase()
                ? -1
                : po1[key].toLowerCase() > po2[key].toLowerCase()
                  ? 1
                  : 0;
            break;
          case 'number':
            result = po1[key] - po2[key];
            break;
          default:
            result = po1 < po2 ? -1 : po1 > po2 ? 1 : 0;
        }

        // If not ascending, flip sort results
        if (sortCriteria[i].direction !== 'ascending') {
          result *= -1;
        }
        // If result is 0, let the loop continue running to check the next condition in the sort criteria.
        // Otherwise return the value so things are sorted.
        if (result !== 0) {
          return result;
        }
      }
    }

    return 0;
  };

  const outputRows = [];

  // Create the table header.
  outputRows.push(
    <LineHeader
      key={'header'}
      lineFormat={lineFormat}
      data={sortedData}
      updateFullColumn={updateFullColumn}
    />
  );

  let totalResults = 0;

  if (!hasDataLookupFinished) {
    outputRows.push(
      <tr className={'filterWarning'} key="gettingFromNS">
        <td colSpan={'100%'}>Loading...</td>
      </tr>
    );
  }

  if (hasDataLookupFinished) {
    totalResults = Object.keys(data).length;
  }

  if (hasDataLookupFinished && totalResults > 0) {
    // Render the Open PO Data
    let filteredResults = 0;
    let filteredHidden = 0;

    for (const [key, value] of Object.entries(data)) {
      sortedData.push({
        ...value,
        key,
      });
    }

    sortedData.sort(sortFunction);

    /*
     * Render
     */
    let visibleRowIndex = 0;

    for (const index in sortedData) {
      const dataRow = sortedData[index];
      sortedData[index].isVisible = true;

      let filterMatchFail = true;
      if (searchFilters?.length) {
        for (let i = 0; i < searchFilters.length; i++) {
          // Make sure the value is not null
          if (
            dataRow[searchFilters[i].key] !== undefined &&
            dataRow[searchFilters[i].key] !== null
          ) {
            if (searchFilters[i].type === 'string') {
              filterMatchFail =
                filterMatchFail &&
                dataRow[searchFilters[i].key]
                  .toLowerCase()
                  .indexOf(searchFilter.toLowerCase()) < 0;
            } else if (searchFilters[i].type === 'date') {
              const date = searchFilter.split('/');

              // Remove leading zeroes on the day/month.
              if (date[0]) {
                if (date[0][0] === '0') {
                  date[0] = date[0].substring(1);
                }
              }
              if (date[1]) {
                if (date[1][0] === '0') {
                  date[1] = date[1].substring(1);
                }
              }
              const dateCombined = date.join('/');

              filterMatchFail =
                filterMatchFail &&
                dataRow[searchFilters[i].key].toString().indexOf(dateCombined) <
                  0;
            } else if (searchFilters[i].type === 'number') {
              filterMatchFail =
                filterMatchFail &&
                dataRow[searchFilters[i].key]
                  .toString()
                  .indexOf(searchFilter.toLowerCase()) < 0;
            }
          }
        }
      } else {
        filterMatchFail = false;
      }

      if (checkboxFilters && !filterMatchFail) {
        for (let i = 0; i < checkboxFilters.length; i++) {
          if (filterMatchFail === true) {
            break;
          }

          // Only check the checkbox filters if they are checked
          if (checkboxFilters[i].value === true) {
            if (checkboxFilters[i].condition.type === 'equals') {
              filterMatchFail =
                dataRow[checkboxFilters[i].key] !==
                checkboxFilters[i].condition.values;
            } else if (checkboxFilters[i].condition.type === 'anyof') {
              // Compare the list of values until one is found to be equal
              for (
                let j = 0;
                j < checkboxFilters[i].condition.values.length;
                j++
              ) {
                if (
                  checkboxFilters[i].condition.values[j] ===
                  dataRow[checkboxFilters[i].key]
                ) {
                  filterMatchFail = false;
                  break;
                }
                filterMatchFail = true;
              }
            }
          }
        }
      }

      // Filter by search criteria while we work.
      if (filterMatchFail) {
        // Don't increment 'current' here because that variable is used to track
        // which page of visible results we're on, but orders that don't match
        // the filter aren't within the visible results.
        filteredHidden++;
        sortedData[index].isVisible = false;
      }

      if (sortedData[index].isVisible) {
        filteredResults++;
        visibleRowIndex++;
      }

      // If we want data starting at a specific page, skip previous pages
      if (
        sortedData[index].isVisible &&
        visibleRowIndex <= openPage * pageSize
      ) {
        sortedData[index].isVisible = false;
      }

      // If we've processed 1 page worth of records, skip remaining pages
      if (
        sortedData[index].isVisible &&
        visibleRowIndex > openPage * pageSize + pageSize
      ) {
        sortedData[index].isVisible = false;
      }

      const rowHighlightClass =
        sortedData[index].isVisible && visibleRowIndex % 2 === 0;

      if (sortedData[index].isVisible) {
        const row = (
          <Line
            lineType={props.lineType}
            highlightClass={rowHighlightClass}
            visible={sortedData[index].isVisible}
            key={dataRow.key}
            data={dataRow}
            objKey={dataRow.key}
            updateSingleCell={updateSingleCell}
            lineFormat={lineFormat}
            lineSettings={props.lineSettings}
          />
        );
        outputRows.push(row);
      }
    }

    pageCount = Math.ceil(filteredResults / pageSize);

    if (showPagination && filteredResults !== 0) {
      const pageUpperCount = (openPage + 1) * pageSize;
      const adjustedPageSize =
        pageSize === Infinity ? filteredResults : pageSize;
      const needsNavigationArrows = pageSize !== Infinity && pageCount > 1;

      pagination = (
        <div className={'pt-1.5 text-right'}>
          {needsNavigationArrows ? (
            <>
              <FontAwesomeIcon
                className={'mx-2 cursor-pointer'}
                icon={faAngleDoubleLeft}
                onClick={() => goToPage(0)}
              />
              <FontAwesomeIcon
                className={'mx-2 cursor-pointer'}
                icon={faAngleLeft}
                onClick={() => goToPage(openPage - 1)}
              />
            </>
          ) : (
            <></>
          )}
          <span>
            {openPage * adjustedPageSize + 1} -{' '}
            {filteredResults > pageUpperCount
              ? pageUpperCount
              : filteredResults}{' '}
            of {filteredResults}
          </span>
          {needsNavigationArrows ? (
            <>
              <FontAwesomeIcon
                className={'mx-2 cursor-pointer'}
                icon={faAngleRight}
                onClick={() => goToPage(openPage + 1)}
              />
              <FontAwesomeIcon
                className={'mx-2 cursor-pointer'}
                icon={faAngleDoubleRight}
                onClick={() => goToPage(pageCount - 1)}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }

    // A single row means we only have the table header. Show a "no results" message
    if (searchFilter && outputRows.length === 1) {
      outputRows.push(
        <tr className={'filterWarning'} key="NoOpenPOs">
          <td colSpan={'100%'} className="flex-item">
            No results that match your search criteria
          </td>
        </tr>
      );
    } else if (filteredHidden > 0) {
      // If any results are hidden by the search, let the user know.
      outputRows.push(
        <tr className={'filterWarning'} key="filteredPOs">
          {filteredHidden > 1 ? (
            <td colSpan={'100%'} className="flex-item">
              Hiding {filteredHidden} results that don&apos;t match your search
              criteria
            </td>
          ) : (
            <td colSpan={'100%'} className="flex-item">
              Hiding 1 result that doesn&apos;t match your search criteria
            </td>
          )}
        </tr>
      );
    }
  } else if (hasDataLookupFinished && !data.length) {
    outputRows.push(
      <tr key="NoOpenPOs">
        <td className="flex-item">You have no results at this time</td>
      </tr>
    );
  }

  const searchChange = () => {
    return (
      <div className="text-right">
        <InputWithShiftingLabel
          onChange={value => {
            // Go back to page 0 when searching
            goToPage(0);
            setSearchFilter(value);
          }}
          label="Search"
        ></InputWithShiftingLabel>
      </div>
    );
  };
  const searchToggles = () => {
    const checkboxContent = [];
    if (props.checkboxFilters) {
      for (let i = 0; i < checkboxFilters.length; i++) {
        checkboxContent.push(
          <Checkbox
            label={checkboxFilters[i].label}
            name={'SearchToggles'}
            key={i}
            onChange={e => toggleCheckboxes(e, i)}
          />
        );
      }
    }

    return <div>{checkboxContent}</div>;
  };

  return (
    <div>
      <Section
        title={title}
        className={className}
        header={
          <>
            {searchFilters?.length ? searchChange() : null}
            {searchToggles()}
          </>
        }
        subtitle={
          <TableSuboptions
            suboptions={suboptions}
            lines={sortedData}
            setLines={setData}
            header={header}
            setHeader={setHeader}
          />
        }
      >
        <table className={clsx(`text-left w-full border-spacing-0`)}>
          <tbody>{outputRows}</tbody>
        </table>
        {pagination}
      </Section>
    </div>
  );
};

export {Table};
