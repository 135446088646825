import * as Elements from '../../modules/components/elements.js';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import {Component} from 'react';

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticationFinished: false,
      isPassChangeRequired: true,
    };
  }

  render() {
    const onPasswordChange = result => {
      // A successful password change means that it is no longer required.
      this.setState({isPassChangeRequired: !result});
    };

    if (!this.state.isAuthenticationFinished) {
      $.get({url: '/api/public/login/verify'}).then(authResult => {
        this.setState({
          isAuthenticationFinished: true,
          isPassChangeRequired: authResult.require_password_change,
        });
      });
    }

    if (!this.state.isPassChangeRequired) {
      return <Redirect to={'/'} />;
    }

    return (
      <div>
        <div
          className={
            'grow order-2 bg-secondary border-l border-lightgray-100 h-full overflow-auto px-10'
          }
        >
          <h1>Password Change</h1>
          <div>Please change your password before continuing.</div>
          <Elements.ChangePasswordForm onPasswordChange={onPasswordChange} />
        </div>
      </div>
    );
  }
}

export default ChangePasswordPage;
