import {useEffect, useState, useMemo} from 'react';
import {CSVLink} from 'react-csv';
import Layout from '../../modules/components/layout/Layout.js';
import {Table} from '../../modules/components/table/Table.js';
import Section from '../../modules/components/Section.js';
import DataField from '../../modules/components/DataField.js';
import Badge from '../../modules/components/Badge.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileArrowDown} from '@fortawesome/free-solid-svg-icons';
import {useGetRequest} from '../../modules/hooks/https-hooks.js';
import {STATUS_BADGES} from '../../modules/configuration/statusBadges.js';

// This defines the subtable settings for the quantity pricing table
// that may appear on each line.
const quantityPricingSubtableOptions = {
  title: 'Quantity Pricing',
  lineFormat: [
    {
      key: 'fromQuantity',
      header: 'From Quantity',
      type: 'text',
      align: 'left',
    },
    {
      key: 'rate',
      header: 'Rate',
      type: 'text',
      align: 'left',
    },
    {
      key: 'percent',
      header: 'Percent Discount',
      type: 'percent',
      align: 'left',
    },
    {
      key: 'quantityOrdered',
      header: 'Quantity Ordered',
      type: 'text',
      align: 'left',
    },
  ],
  sortCriteria: [
    {
      key: 'fromQuantity',
      type: 'number',
      direction: 'ascending',
    },
  ],
};

/**
 * Returns an array that is used to create a PK CSV.
 * @param {Object} dataLines
 * @param {string} dataLines.itemNumber
 * @param {string} [dataLines.description]
 * @param {number} [dataLines.totalPrice]
 * @param {number} [dataLines.revision]
 * @param {string} [dataLines.mpn]
 * @param {number} [dataLines.moq]
 * @param {number} [dataLines.pack]
 * @param {number} [dataLines.masterPack]
 * @param {number} [dataLines.supplierPriceNTD]
 * @param {number} [dataLines.addSupplierPriceNTD]
 * @param {number} [dataLines.pricingTierQuantity]
 * @param {Array} dataLines.quantityPricing
 * @param {number} [dataLines.quantityPricing.fromQuantity]
 * @param {number} [dataLines.quantityPricing.rate]
 * @param {number} [dataLines.quantityPricing.percent]
 * @param {number} [dataLines.quantityPricing.quantityOrdered]
 * @param {string} pkNumber
 * @returns {*[]}
 */
const createPKCSV = (dataLines, pkNumber) => {
  const result = [];

  const dataLinesValues = Object.values(dataLines);
  // Some columns should only appear if they are included in the data
  const requiresNTD = dataLinesValues.some(
    value => value.supplierPriceNTD || value.addSupplierPriceNTD
  );
  const showPricingTierQuantity = dataLinesValues.some(
    value => value.pricingTierQuantity
  );

  // Apply file header
  result.push(
    [
      'PK Number',
      'Item Number',
      'Description',
      'Total Price (USD)',
      'Revision',
      'Manufacturer Part Number',
      'Minimum Order Quantity',
      'Pack Quantity',
      'Master Pack Quantity',
      'From Quantity',
      'Rate',
      'Percent Discount',
      'Quantity Ordered',
      // Only add NTD headers if needed
    ]
      .concat(showPricingTierQuantity ? ['Pricing Tier Quantity'] : [])
      .concat(
        requiresNTD ? ['Supplier Price (NTD)', 'Add. Supplier Price (NTD)'] : []
      )
  );

  // Loop through the full data set and process each line.
  for (const value of Object.values(dataLines)) {
    const line = [
      pkNumber,
      value.itemNumber,
      value.description,
      value.totalPrice,
      value.revision,
      value.mpn,
      value.moq,
      value.pack,
      value.masterPack,
    ];

    const pricingTierQuantity = showPricingTierQuantity
      ? [value.pricingTierQuantity]
      : [];

    // Only add NTD information if needed
    let lineNTD = [];
    if (requiresNTD) {
      lineNTD = requiresNTD
        ? [value.supplierPriceNTD, value.addSupplierPriceNTD]
        : [];
    }

    // If there is quantity pricing, create a line for each quantity
    // pricing level.
    if (value.quantityPricing.length) {
      value.quantityPricing.forEach(item => {
        result.push(
          line.concat(
            [item.fromQuantity, item.rate, item.percent, item.quantityOrdered],
            pricingTierQuantity,
            lineNTD
          )
        );
      });
      continue;
    }

    // There is no quantity pricing so it is left blank
    result.push(line.concat(['', '', '', ''], pricingTierQuantity, lineNTD));
  }

  return result;
};

const PKDetail = props => {
  const pkNumber = props.location.pathname.split('/').pop();

  const {data, hasFinished} = useGetRequest(`/api/v1/ns/pk/${pkNumber}`);

  const [lines, setLines] = useState({});
  const [header, setHeader] = useState({});

  useEffect(() => {
    setLines(data?.lines || {});
    setHeader(data?.header || {});
  }, [data]);

  // Generate the CSV format
  const csvData = useMemo(() => createPKCSV(lines, pkNumber), [lines]);

  return (
    <Layout>
      <h1>Purchase Contract {pkNumber}</h1>

      <Section title={'Details'}>
        <div className={'flex flex-wrap content-start gap-2.5'}>
          <DataField label={'Manufacturer'}>{header.manufacturer}</DataField>
          <DataField label={'Status'}>
            <Badge badges={STATUS_BADGES[header.status]} />
          </DataField>
          <DataField label={'CSV'}>
            <CSVLink
              filename={`Tekton_${pkNumber}`}
              data={csvData || []}
              target="_blank"
            >
              <FontAwesomeIcon icon={faFileArrowDown} /> Download
            </CSVLink>
          </DataField>
          <DataField label={'Start Date'}>{header.startDate}</DataField>
          <DataField label={'End Date'}>{header.endDate}</DataField>
          <DataField label={'Memo'}>{header.memo}</DataField>
        </div>
      </Section>
      <Table
        pageSize={Infinity}
        data={lines}
        setData={setLines}
        header={header}
        setHeader={setHeader}
        hasDataLookupFinished={hasFinished}
        title="Lines"
        lineFormat={[
          {
            key: 'selectLine',
            header: null,
            type: 'checkbox',
            align: 'center',
          },
          {
            key: 'quantityPricing',
            header: (
              <>
                View Quantity
                <br />
                Pricing
              </>
            ),
            type: 'collapsingSubline',
            align: 'center',
            options: quantityPricingSubtableOptions,
          },
          {
            key: 'itemNumber',
            header: 'Item Number',
            type: 'text',
            align: 'left',
          },
          {
            key: 'description',
            header: 'Description',
            type: 'text',
            align: 'left',
          },
          {
            key: 'totalPrice',
            header: 'Total Price (USD)',
            type: 'text',
            align: 'left',
          },
          {
            key: 'revision',
            header: 'Revision',
            type: 'text',
            align: 'center',
          },
          {
            key: 'mpn',
            header: (
              <>
                Manufacturer
                <br />
                Part Number
              </>
            ),
            type: 'text',
            align: 'center',
          },
          {
            key: 'moq',
            header: (
              <>
                Minimum
                <br />
                Order Quantity
              </>
            ),
            type: 'text',
            align: 'center',
          },
          {
            key: 'pricingTierQuantity',
            header: (
              <>
                Pricing
                <br />
                Tier Quantity
              </>
            ),
            type: 'text',
            align: 'center',
            hideWhenEmpty: true,
          },
          {
            key: 'pack',
            header: (
              <>
                Pack
                <br />
                Quantity
              </>
            ),
            type: 'text',
            align: 'center',
          },
          {
            key: 'masterPack',
            header: (
              <>
                Master Pack
                <br />
                Quantity
              </>
            ),
            type: 'text',
            align: 'center',
          },
          {
            key: 'supplierPriceNTD',
            header: (
              <>
                Supplier
                <br />
                Price (NTD)
              </>
            ),
            type: 'text',
            align: 'center',
            hideWhenEmpty: true,
          },
          {
            key: 'addSupplierPriceNTD',
            header: (
              <>
                Add. Supplier
                <br />
                Price (NTD)
              </>
            ),
            type: 'text',
            align: 'center',
            hideWhenEmpty: true,
          },
          {
            key: 'lineConfirmed',
            header: 'Status',
            type: 'badge',
            align: 'center',
            options: STATUS_BADGES,
          },
        ]}
        searchFilters={[
          {
            key: 'itemNumber',
            type: 'string',
          },
        ]}
        sortCriteria={[
          {
            key: 'itemNumber',
            type: 'string',
            direction: 'ascending',
          },
        ]}
        checkboxFilters={[
          {
            key: 'lineConfirmed',
            condition: {
              type: 'equals',
              values: 'Needs Review',
            },
            label: 'Needs Review Only',
            value: false,
          },
        ]}
        suboptions={[
          {
            type: 'confirmdropdown',
            options: {
              orderNumber: pkNumber,
              key: 'ConfirmationDropdown',
            },
          },
        ]}
      />
    </Layout>
  );
};

export default PKDetail;
