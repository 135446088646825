import {useState} from 'react';

/**
 * Creates a hook that fires when a GET request
 * returns with a result.
 * @param url
 * @param runOnDeclaration
 * @returns {{hasFinished: boolean, data: {},
 *  hasStarted: boolean, sendGet: sendGet}}
 */
const useGetRequest = (url, runOnDeclaration = true) => {
  const [data, setData] = useState({});
  const [hasStarted, setHasStarted] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const sendGet = () => {
    if (!hasStarted) {
      setHasStarted(true);

      fetch(url, {method: 'GET'})
        .then(response => {
          // Check if the response result was ok (true if code is 200-299)
          if (response.ok) {
            // If the result was ok, parse it and store the data.
            response.json().then(results => {
              setData(results || {});
            });
          } else {
            // If the result was not ok then store an empty object.
            setData({});
          }
          setHasFinished(true);
        })
        .catch(() => {});
    }
  };

  // Run the request if it is meant to be run on declaration.
  if (!hasStarted && runOnDeclaration) {
    sendGet();
  }

  return {
    data,
    setData,
    hasStarted,
    hasFinished,
    sendGet,
  };
};

/**
 * Creates a hook that fires whenever a response
 * has been received from a post request.
 * sendPost must be called to actually send the post request.
 * @param url
 * @returns {{result: {}, sendPost: sendPost,
 *  hasFinished: boolean, hasStarted: boolean}}
 */
const usePostRequest = url => {
  const [result, setResult] = useState({});
  const [hasStarted, setHasStarted] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  // We only want to send a post request for this hook
  // when the sendPost function is called.
  const sendPost = payload => {
    if (!hasStarted) {
      setHasStarted(true);
      setHasFinished(false);

      fetch(url, {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json'}),
        body: JSON.stringify(payload),
      })
        .then(response => {
          // Check if the response was ok (true if code is 200-299)
          if (response.ok) {
            // If the result was ok, parse it and store the data.
            response.json().then(results => {
              setResult(results || {});
            });
          } else {
            // If the result was not ok then store an empty object.
            setResult({});
          }
          setHasStarted(false);
          setHasFinished(true);
        })
        .catch(() => {});
    }
  };

  return {
    result,
    sendPost,
    hasStarted,
    hasFinished,
  };
};

/**
 * Creates a hook that fires whenever a response
 * has been received from a post request.
 * sendPost must be called to actually send the post request.
 * @param url
 * @returns {{result: [], sendPost: sendPost,
 *  hasFinished: boolean, hasStarted: boolean}}
 */
const usePostRequestBatch = url => {
  const [result, setResult] = useState([]);
  const [hasStarted, setHasStarted] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const allRequests = [];

  const sendPost = payloadList => {
    setHasStarted(true);
    setHasFinished(false);

    for (const payload of payloadList) {
      allRequests.push(
        fetch(url, {
          method: 'POST',
          headers: new Headers({'Content-Type': 'application/json'}),
          body: JSON.stringify(payload),
        })
      );
    }

    processResults(allRequests).then(response => {
      setResult(response);

      setHasStarted(false);
      setHasFinished(true);
    });
  };

  return {
    result,
    sendPost,
    hasStarted,
    hasFinished,
  };
};

/**
 * Used to process many requests asynchronously.
 * @see usePostRequestBatch
 * @param allRequests
 * @returns {Promise<*[]>}
 */
const processResults = async allRequests => {
  const responses = await Promise.all(allRequests);

  const processedResults = [];

  for (const response of responses) {
    if (response.ok) {
      // If the result was ok, parse it and store the data.
      const results = await response.json();
      processedResults.push(results || {});
    }
  }

  return processedResults;
};

export {useGetRequest, usePostRequest, usePostRequestBatch};
