import {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {Checkbox} from '../../Checkbox.js';

/**
 * Defines a checkbox cell for a header
 * @param {Object} props.data
 * @param {function} props.onChange
 * @param {Object} props.column
 * @param {string} [props.className]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CheckboxCellHeader = props => {
  const {data, onChange, column, className} = props;

  // Valid states are 'unchecked', 'checked', and 'indeterminate'
  const [state, setState] = useState('unchecked');

  useEffect(() => {
    if (data.every(value => !value[column.key] || !value.isVisible)) {
      setState('unchecked');
    } else if (data.every(value => value[column.key] || !value.isVisible)) {
      setState('checked');
    } else {
      setState('indeterminate');
    }
  }, [data]);

  const selectAllCheckboxes = value => {
    // Select all checkboxes
    onChange(value);
  };

  return (
    <th className={className}>
      <Checkbox
        checked={state === 'checked'}
        indeterminate={state === 'indeterminate'}
        onChange={selectAllCheckboxes}
        label={<FontAwesomeIcon icon={faChevronDown} />}
      />
    </th>
  );
};

export default CheckboxCellHeader;
