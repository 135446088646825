import {Table} from '../../../modules/components/table/Table';

/**
 *
 * @param {object} expenses
 * @param {string} expenses.name
 * @param {string} expenses.memo
 * @param {number} expenses.amount
 * @constructor
 */
export const ExpenseTable = ({expenses}) => {
  const showNTDColumn = expenses.some(expense => expense.amountNTD);

  return (
    <Table
      pageSize={Infinity}
      header={
        <tr className="header" key="header">
          <th>Description</th>
          <th className={'text-center'}>Amount</th>
          {/* Only add this column if it is needed */}
          {showNTDColumn && <th className={'text-center'}>Amount (NTD)</th>}
        </tr>
      }
      data={expenses}
      hasDataLookupFinished={true}
      title={'Expenses'}
      lineFormat={[
        {
          key: 'memo',
          header: 'Description',
          type: 'text',
          align: 'left',
        },
        {
          key: 'amount',
          header: 'Amount',
          type: 'currency',
          align: 'center',
        },
        // Only add this column if it is needed
        ...(showNTDColumn
          ? [
              {
                key: 'amountNTD',
                header: 'Amount (NTD)',
                type: 'currency',
                align: 'center',
              },
            ]
          : []),
      ]}
    ></Table>
  );
};
