const STATUS_BADGES = {
  'Needs Review': [
    {
      value: 'NEEDS REVIEW',
      color: 'bg-yellow',
    },
  ],
  Confirmed: [
    {
      value: 'Confirmed',
      color: 'bg-green',
    },
  ],
  Rejected: [
    {
      value: 'Rejected',
      color: 'bg-red',
    },
  ],
  Expired: [
    {
      value: 'Expired',
      color: 'bg-orange',
    },
  ],
};

export {STATUS_BADGES};
