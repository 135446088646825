import {useEffect, useRef} from 'react';

/**
 * Create a Checkbox
 * @param props
 * @param {function} props.onChange
 * @param {string} [props.label]
 * @param {boolean} [props.checked]
 * @param {boolean} [props.indeterminate]
 * @param {boolean} [props.disabled]
 * @returns {*}
 * @constructor
 */
const Checkbox = props => {
  const {label, checked, indeterminate, disabled, onChange} = props;

  // An indeterminate effect can only be applied through
  // a direct reference to the checkbox.
  const checkboxRef = useRef();
  useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  const input = (
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      ref={checkboxRef}
      onChange={e => {
        onChange(e.target.checked);
      }}
      className={'w-[1rem] h-[1rem]'}
    />
  );

  if (label) {
    return (
      <label>
        {input} {label}
      </label>
    );
  }

  return input;
};

export {Checkbox};
