const ITEM_PRIORITY_STATUS = {
  URGENT_OVERDUE_VERY_HIGH_PRIORITY: 0,
  URGENT_HIGH_PRIORITY: 1,
  OVERDUE_HIGH_PRIORITY: 2,
  NORMAL_PRIORITY: 3,
  LOW_PRIORITY: 4,
  CLOSED: 5,
};

const ITEM_PRIORITY_BADGES = {
  [ITEM_PRIORITY_STATUS.URGENT_OVERDUE_VERY_HIGH_PRIORITY]: [
    {
      value: 'URGENT',
      color: 'bg-darkorange',
    },
    {
      value: 'OVERDUE',
      color: 'bg-orange',
    },
  ],
  [ITEM_PRIORITY_STATUS.URGENT_HIGH_PRIORITY]: [
    {
      value: 'URGENT',
      color: 'bg-orange',
    },
  ],
  [ITEM_PRIORITY_STATUS.OVERDUE_HIGH_PRIORITY]: [
    {
      value: 'OVERDUE',
      color: 'bg-orange',
    },
  ],
  [ITEM_PRIORITY_STATUS.NORMAL_PRIORITY]: [
    {
      value: 'NORMAL',
      color: 'bg-lightblue',
    },
  ],
  [ITEM_PRIORITY_STATUS.CLOSED]: [
    {
      value: 'CLOSED',
      color: 'bg-lightgray',
    },
  ],
};

export {ITEM_PRIORITY_BADGES};
