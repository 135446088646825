import clsx from 'clsx';
import TdLink from '../TdLink.js';

/**
 * Create Text Cell
 * @param props
 * @param {string} props.children - Text to display
 * @param {string} [props.toPath]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const TextCell = props => {
  const {toPath, className, children} = props;

  return (
    <TdLink to={toPath} className={clsx(className)}>
      {children}
    </TdLink>
  );
};

export default TextCell;
