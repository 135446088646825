import $ from 'jquery';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faCheck} from '@fortawesome/free-solid-svg-icons';
import {InputWithShiftingLabel} from './TextInput.js';
import {Button} from './Button.js';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import {Component} from 'react';

const MINIMUM_PASSWORD_LENGTH = 16;

/**
 * This component only exists in order to not break functionality
 * on the PO Details page. It should not be used for any new pages.
 * Once the PO Details page is redone this component should be deleted.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SectionDeprecated = props => {
  let search = '';
  let searchToggles = '';
  const sectionPrefix = props.sectionPrefix || '';
  if (props.searchChange) {
    search = (
      <div className="text-right">
        <InputWithShiftingLabel
          onChange={props.searchChange}
          label="Search"
          id={`${sectionPrefix}_search`}
        ></InputWithShiftingLabel>
      </div>
    );

    if (props.searchToggles) {
      searchToggles = props.searchToggles;
    }
  }

  return (
    <div
      className={clsx(
        `bg-white p-5 mb-10`,
        `shadow-[0px_2px_5px_0px_HSLA(0,0%,84.3%,0.5)]`,
        props.className
      )}
    >
      <div className="sectionHeader flex flex-wrap content-start gap-2.5">
        <h2 className={'grow'}>{props.title}</h2>
        <div className={'grow'}>
          {search}
          {searchToggles}
        </div>
      </div>
      {props.children}
    </div>
  );
};

/**
 *
 */
class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {message: ''};

    this.changePassword = this.changePassword.bind(this);
  }

  changePassword() {
    const newPassword = $('#newPass').val();
    const newPassConfirm = $('#newPassConfirm').val();

    /*
     * Validate password against requirements
     */
    if (newPassword !== newPassConfirm) {
      this.setState({
        message: (
          <div style={{color: '#C00'}}>
            Password and Confirmed Password don&apos;t match
          </div>
        ),
      });
      return;
    }

    if (newPassword.length < MINIMUM_PASSWORD_LENGTH) {
      this.setState({
        message: (
          <div style={{color: '#C00'}}>
            Password must be at least {MINIMUM_PASSWORD_LENGTH} characters long
          </div>
        ),
      });
      return;
    }

    if (
      !/[0-9]/g.test(newPassword) ||
      !/[a-z]/g.test(newPassword.toLowerCase())
    ) {
      this.setState({
        message: (
          <div style={{color: '#C00'}}>
            Password must include at both letters and numbers
          </div>
        ),
      });
      return;
    }

    /*
     * Tests Passed
     */

    this.setState({
      message: (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} spin /> Changing Password...
        </div>
      ),
    });

    $.post({
      url: '/api/v1/user/password/set',
      data: {newPassword},
    }).then(result => {
      if (!result.success) {
        this.setState({
          message: <div style={{color: '#C00'}}>{result.error}</div>,
        });
        return;
      }

      if (this.props.onPasswordChange) {
        this.props.onPasswordChange(result.success);
      }

      this.setState({
        message: (
          <div>
            <span style={{color: 'green'}}>
              <FontAwesomeIcon icon={faCheck} />
            </span>{' '}
            Password Updated
          </div>
        ),
      });
    });
  }

  render() {
    return (
      <div>
        <div id="passwordRequirements">
          <ul>
            <li>
              <span
                style={{
                  color: '#C00',
                  fontWeight: 'bold',
                }}
              >
                Must
              </span>{' '}
              be at least {MINIMUM_PASSWORD_LENGTH} characters long
            </li>
            <li>
              <span
                style={{
                  color: '#C00',
                  fontWeight: 'bold',
                }}
              >
                Must
              </span>{' '}
              contain both letters and numbers
            </li>
            <li>May contain special characters</li>
          </ul>
        </div>
        <InputWithShiftingLabel
          type="password"
          label="New Password"
          id="newPass"
        />
        <InputWithShiftingLabel
          type="password"
          label="Confirm Password"
          id="newPassConfirm"
        />
        {this.state.message}
        <Button onClick={this.changePassword}>Submit</Button>
      </div>
    );
  }
}

/**
 * Converts a date or string date into a string that can be used by HTML
 * input fields of type date.
 *
 * Example input: "10/1/2021"
 * Example output: "2021-10-1"
 *
 * @param date
 * @returns {string|null}
 */
const htmlDate = date => {
  let result = date;

  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    try {
      result = new Date(date);
    } catch (e) {
      return '';
    }
  }

  result = `${result.getFullYear()}-${
    result.getMonth() + 1 < 10 ? '0' : ''
  }${result.getMonth() + 1}-${
    result.getDate() < 10 ? '0' : ''
  }${result.getDate()}`;

  return result;
};

/**
 * Converts a JS Date into a string.
 *
 * I've opted to create this function rather than using Date.toLocaleString()
 * because toLocaleString returns an improperly formatting string when run in
 * server-side code.
 *
 * @param date
 * @returns {string}
 */
const prettyDate = date => {
  if (!date) {
    return null;
  }

  let newDate = date;
  if (typeof date === 'string') {
    try {
      newDate = new Date(date);
    } catch (e) {
      return '';
    }
  }
  return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
};

export {SectionDeprecated, ChangePasswordForm, htmlDate, prettyDate};
