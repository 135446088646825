import CheckboxCellHeader from '../cell/CheckboxCellHeader.js';

/**
 * Header line for a table
 * @param props
 * @param {Object} props.lineFormat
 * @param {Object} props.data
 * @param {function} props.updateFullColumn
 * @returns {JSX.Element}
 * @constructor
 */
const LineHeader = props => {
  const {lineFormat, data, updateFullColumn} = props;

  const cells = [];

  lineFormat.forEach(column => {
    // Determine styling for this cell
    let alignment;
    if (column.align === 'right') {
      alignment = 'text-right';
    } else if (column.align === 'center') {
      alignment = 'text-center';
    }

    // Create a unique key for the cell
    const key = column.key;

    // Some headers require a custom cell design.
    if (column.type === 'checkbox') {
      cells.push(
        <CheckboxCellHeader
          data={data}
          onChange={newValue => updateFullColumn(newValue, key)}
          column={column}
          className={alignment}
          key={key}
        />
      );
    } else {
      // Default header style
      cells.push(
        <th className={alignment} key={key}>
          {column.header}
        </th>
      );
    }
  });

  return <tr key={'header'}>{cells}</tr>;
};

export default LineHeader;
