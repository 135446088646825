import clsx from 'clsx';
import TdLink from '../TdLink.js';

/**
 * Create Currency Cell
 * @param props
 * @param {number} props.amount - Currency amount to display
 * @param {string} [props.toPath]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
export const CurrencyCell = props => {
  const {toPath, className, amount} = props;

  return (
    <TdLink to={toPath} className={clsx(className)}>
      {amount.toFixed(2)}
    </TdLink>
  );
};
