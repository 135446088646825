import {ConfirmationButton} from '../../Button.js';

/**
 * Create Confirmation Button Cell
 * @param props
 * @param {string} props.url - URL to send confirmation
 * @param {boolean} props.confirmed - If already confirmed or not.
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationButtonCell = props => {
  const {className, url, confirmed} = props;

  return (
    <td className={className}>
      <ConfirmationButton url={url} confirmed={confirmed} />
    </td>
  );
};

export default ConfirmationButtonCell;
