import {htmlDate} from './elements.js';
import clsx from 'clsx';

/**
 * Format and display a date input
 * @param props
 * @param {Date|string} props.children
 * @param {boolean} [props.disabled]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const DisplayDate = props => {
  const {className, children, disabled} = props;

  const value = htmlDate(children);

  return value ? (
    <input
      type={'date'}
      disabled={disabled}
      value={value}
      required
      className={clsx(
        className,
        `text-base`,
        `border border-solid border-transparent`,
        `bg-transparent`,
        'text-black'
      )}
    />
  ) : (
    ''
  );
};

export default DisplayDate;
