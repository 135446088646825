import clsx from 'clsx';
import {Button} from '../../Button.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';

/**
 * Button that handles the handles collapsing and uncollapsing the subline
 * @param props
 * @returns {JSX.Element}
 * @param {boolean} props.open - Boolean status if the button is open or closed.
 * @param {function} props.setOpen - Function to set the state of the hook
 * @param {boolean} [props.disabled] - Disable button interaction
 * @param {string} [props.className]
 * @constructor
 */
const SublineCollapseCell = props => {
  const {className, open, setOpen, disabled} = props;

  return (
    <td className={className}>
      <Button
        onClick={() => setOpen(!open)}
        mini={true}
        inverted={open}
        disabled={disabled}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className={clsx('ease-linear duration-75', open ? 'rotate-90' : '')}
        />
      </Button>
    </td>
  );
};

export default SublineCollapseCell;
