import auth from '../../auth.js';
import {Redirect} from 'react-router-dom';
import {Component} from 'react';

/**
 *
 */
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {loggingOut: true};
  }

  render() {
    if (this.state.loggingOut) {
      // Log the user out upon visiting this page.
      auth.logout().then(() => {
        this.setState({loggingOut: false});
      });

      return (
        <div>
          <div className="grow order-2 bg-secondary border-l border-lightgray-100 h-full overflow-auto px-10">
            <h1>Logging out...</h1>
          </div>
        </div>
      );
    }
    return <Redirect to="/login" />;
  }
}

export default Logout;
