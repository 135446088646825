import TdLink from '../TdLink.js';
import {Checkbox} from '../../Checkbox.js';

/**
 * Create Checkbox Cell
 * @param props
 * @param {function} props.onChange
 * @param {boolean} [props.checked]
 * @param {string} [props.className]
 * @param {string} [props.toPath]
 * @returns {JSX.Element}
 * @constructor
 */
const CheckboxCell = props => {
  const {onChange, toPath, className, checked} = props;

  return (
    <TdLink to={toPath} className={className}>
      <Checkbox checked={checked} onChange={onChange} />
    </TdLink>
  );
};

export default CheckboxCell;
