/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DataField = props => {
  const label = props.label ? (
    <div className="dataLabel">{props.label}</div>
  ) : (
    ''
  );

  return (
    <div className="dataField">
      {label}
      <div className="dataValue">{props.value || props.children || '-'}</div>
    </div>
  );
};

export default DataField;
